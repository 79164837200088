import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import "../assets/scss/main.scss"

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === "/") {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet title={data.site.siteMetadata.title}>
            <html lang="en" />
            <meta
              property="og:image"
              content="https://mensgroup.us/opengraph.jpg"
            />
            <meta
              property="og:description"
              content={data.site.siteMetadata.description}
            />
            <meta property="keywords" content={"mens group, men, connection"} />
            <meta
              property="description"
              content={data.site.siteMetadata.description}
            />
            <meta property="og:url" content="https://mensgroup.us" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <noscript>MensGroup.us uses javascript</noscript>
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
